var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm}},[(_vm.questionFlag == true)?_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.paperName))])]),_vm._l((_vm.ruleForm.list),function(item,index){return _c('div',{key:index,staticClass:"container-card"},[(item.topicId == '1')?_c('el-form-item',{attrs:{"prop":`list.${index}.answers`,"label":`问题${index + 1}：${item.question}`,"rules":[
						{
							required: item.isRequired == '2' ? true : false,
							message: '请至少勾选一个',
							trigger: 'change',
						},
					]}},[_c('el-radio-group',{on:{"change":function($event){return _vm.selectChange(item, item.answers)}},model:{value:(item.answers),callback:function ($$v) {_vm.$set(item, "answers", $$v)},expression:"item.answers"}},_vm._l((item.list),function(question,k){return _c('el-radio',{key:k,attrs:{"label":question.selectId}},[_vm._v(_vm._s(question.selectContent))])}),1)],1):(item.topicId == '2')?_c('el-form-item',{attrs:{"prop":`list.${index}.checkList`,"label":`问题${index + 1}：${item.question}`,"rules":[
						{
							required: item.isRequired == '2' ? true : false,
							message: '请至少勾选一个',
							trigger: 'change',
						},
					]}},[_c('el-checkbox-group',{on:{"change":_vm.checkboxChange},model:{value:(item.checkList),callback:function ($$v) {_vm.$set(item, "checkList", $$v)},expression:"item.checkList"}},_vm._l((item.list),function(question,k){return _c('el-checkbox',{key:k,attrs:{"label":question.selectId},on:{"change":function($event){return _vm.getCheckboxQuestion(item)}}},[_vm._v(_vm._s(question.selectContent))])}),1)],1):(item.topicId == '3')?_c('el-form-item',{attrs:{"prop":`list.${index}.input`,"label":`问题${index + 1}：${item.question}`,"rules":[
						{
							required: item.isRequired == '2' ? true : false,
							message: '请输入信息',
							trigger: ['change', 'blur'],
						},
					]}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入内容"},on:{"change":function($event){return _vm.inputChange(item, item.input)}},model:{value:(item.input),callback:function ($$v) {_vm.$set(item, "input", $$v)},expression:"item.input"}})],1):_vm._e(),_c('el-divider')],1)}),_c('div',{staticClass:"add-button"},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("提交")])],1)],1)],2):_c('el-card',{staticClass:"box-card"},[_c('empty-box',{staticClass:"empty"},[_vm._v("暂无问卷")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }