<template>
	<div class="root">
		<el-form :model="ruleForm" ref="ruleForm">
			<el-card v-if="questionFlag == true" class="box-card">
				<div slot="header" class="clearfix">
					<span class="title">{{ paperName }}</span>
				</div>
				<div
					class="container-card"
					v-for="(item, index) in ruleForm.list"
					:key="index"
				>
					<el-form-item
						v-if="item.topicId == '1'"
						:prop="`list.${index}.answers`"
						:label="`问题${index + 1}：${item.question}`"
						:rules="[
							{
								required: item.isRequired == '2' ? true : false,
								message: '请至少勾选一个',
								trigger: 'change',
							},
						]"
					>
						<!-- 单选 -->
						<el-radio-group
							v-model="item.answers"
							@change="selectChange(item, item.answers)"
						>
							<el-radio
								v-for="(question, k) in item.list"
								:key="k"
								:label="question.selectId"
								>{{ question.selectContent }}</el-radio
							>
						</el-radio-group>
					</el-form-item>
					<!-- 多选 -->
					<el-form-item
						v-else-if="item.topicId == '2'"
						:prop="`list.${index}.checkList`"
						:label="`问题${index + 1}：${item.question}`"
						:rules="[
							{
								required: item.isRequired == '2' ? true : false,
								message: '请至少勾选一个',
								trigger: 'change',
							},
						]"
					>
						<el-checkbox-group
							v-model="item.checkList"
							@change="checkboxChange"
						>
							<el-checkbox
								v-for="(question, k) in item.list"
								:key="k"
								:label="question.selectId"
								@change="getCheckboxQuestion(item)"
								>{{ question.selectContent }}</el-checkbox
							>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item
						v-else-if="item.topicId == '3'"
						:prop="`list.${index}.input`"
						:label="`问题${index + 1}：${item.question}`"
						:rules="[
							{
								required: item.isRequired == '2' ? true : false,
								message: '请输入信息',
								trigger: ['change', 'blur'],
							},
						]"
					>
						<el-input
							type="textarea"
							:rows="2"
							placeholder="请输入内容"
							@change="inputChange(item, item.input)"
							v-model="item.input"
						>
						</el-input
					></el-form-item>
					<el-divider></el-divider>
				</div>
				<div class="add-button">
					<el-form-item>
						<el-button
							type="primary"
							plain
							@click="submitForm('ruleForm')"
							>提交</el-button
						></el-form-item
					>
				</div>
			</el-card>
			<el-card class="box-card" v-else
				><empty-box class="empty">暂无问卷</empty-box>
			</el-card>
		</el-form>
	</div>
</template>

<script>
import emptyBox from '@/components/empty.vue'
import { getQuestion_api, saveQuestion_api } from '@/api/user'
import { localeData } from 'moment'
export default {
	inject: ['reload'],
	components: { emptyBox },
	data() {
		return {
			value: true,
			rules: {},
			questionFlag: null,
			paperId: '', //问卷
			paperName: '', //	问卷名称
			ruleForm: {
				list: [],
			},
			form: {
				list: [],
			},
			isDisabled: false,
			checkboxList: [], //多选框change时拿到的selectId
		}
	},
	methods: {
		//获取问卷信息
		async getQuestion() {
			let { msg, code, data } = await getQuestion_api({})
			if (code == 200) {
				this.questionFlag = data.questionFlag
				this.paperId = data.questionnaireInfo.id
				this.paperName = data.questionnaireInfo.paperTitle
				data.questionList.map((item, index) => {
					this.ruleForm.list.push(
						Object.assign(item, {
							answers: '',
							checkList: [],
							input: '',
						})
					)
				})
			}
		},
		async addSubmit() {
			let { msg, code, data } = await saveQuestion_api({
				paperId: this.paperId,
				paperName: this.paperName,
				list: this.form.list,
			})
			if (code == 200) {
				this.$message({
					type: 'success',
					message: msg,
				})
				this.reload()
			}
		},
		submitForm(formName) {
			if (this.isDisabled)
				return this.$message.error('请勿频繁点击,正在提交问卷')
			this.isDisabled = true
			setTimeout(() => {
				this.isDisabled = false
			}, 2000)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.addSubmit()
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		//获取selectContent
		getSelectContent(question, val) {
			let obj = {}
			obj = question.list.find((item) => {
				return item.selectId === val
			})
			return obj.selectContent
		},
		//填空题
		inputChange(question, inputContent) {
			this.form.list.push({
				questionId: question.id,
				questionName: question.question,
				selectId: '0',
				selectContent: inputContent,
			})
		},
		//单选
		selectChange(question, selectId) {
			let selectContent = this.getSelectContent(question, selectId)
			this.form.list.push({
				questionId: question.id,
				questionName: question.question,
				selectId: selectId,
				selectContent: selectContent,
			})
			let hash = {}
			this.form.list = this.form.list.reduce((preVal, curVal) => {
				hash[curVal.questionId]
					? ''
					: (hash[curVal.questionId] = true && preVal.push(curVal))
				return preVal
			}, [])
		},
		//多选
		getCheckboxQuestion(question) {
			setTimeout(() => {
				if (
					JSON.stringify(this.form.list).indexOf(
						JSON.stringify(question.id)
					) === -1
				) {
					this.checkboxList.forEach((item) => {
						let selectContent = this.getSelectContent(
							question,
							item
						)
						this.form.list.push({
							questionId: question.id,
							questionName: question.question,
							selectId: item,
							selectContent: selectContent,
						})
					})
				} else {
					let formList = this.form.list.find(
						(x) => x.questionId == question.id
					)
					this.form.list.splice(this.form.list.indexOf(formList), 1)
					let list = {}
					for (let i = 0; i < this.checkboxList.length; i++) {
						let selectContent = this.getSelectContent(
							question,
							this.checkboxList[i]
						)
						if (i == 0) {
							list.questionId = question.id
							list.questionName = question.question
							list.selectId = this.checkboxList[i]
							list.selectContent = selectContent
						} else {
							list.questionId = question.id
							list.questionName = question.question
							list.selectId += ',' + this.checkboxList[i]
							list.selectContent += ',' + selectContent
						}
					}
					this.form.list.push(list)
				}
			}, 100)
		},
		//获取多选框的更新后的值
		checkboxChange(val) {
			this.checkboxList = val
		},
	},
	mounted() {
		this.getQuestion()
	},
}
</script>

<style lang="less" scoped>
.root {
	.box-card {
		margin-bottom: 11vh;
		.clearfix {
			.title {
				font-weight: bold;
				font-size: medium;
			}
		}
		.add-button {
			text-align: center;
		}
		.question-pagination {
			overflow: hidden;
			/deep/.el-pagination {
				float: right;
			}
		}
		.container-card {
			margin-bottom: 3vh;
			.el-form-item {
				/deep/.el-form-item__label {
					width: 100%;
					text-align: left;
					font-weight: bold;
					font-size: medium;
				}
				/deep/.el-form-item__content {
					.el-radio-group {
						margin-left: 3vw;
						.el-radio {
							width: 100%;
							margin-bottom: 2vh;
							font-size: medium;
							&:first-child {
								margin-top: 13px;
							}
						}
					}
					.el-checkbox-group {
						margin-left: 3vw;
						.el-checkbox {
							line-height: 1;
							width: 100%;
							margin-bottom: 2vh;
							font-size: medium;
							&:first-child {
								margin-top: 13px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
